'use client'; // Error components must be Client Components

import { Button } from '@/components/ui/button';
import {
  appRouterSsrErrorHandler,
  AppRouterErrorProps,
} from '@highlight-run/next/ssr';
import { ArrowLeft } from 'lucide-react';
import Link from 'next/link';

export default appRouterSsrErrorHandler(
  ({ error, reset }: AppRouterErrorProps) => {
    return (
      <div className="flex h-screen w-full flex-col items-center justify-center space-y-2">
        <h1 className="font-semibold text-destructive">
          Oh no ! Something went wrong while proccessing your request
        </h1>
        <p className="text-muted-foreground">
          If this issue persists, please get in touch with the dev team and
          include the following details
        </p>
        <p className="font-mono text-sm text-muted-foreground">
          {error.message}
        </p>

        <Button variant="ghost" asChild>
          <Link href={'..'} className="text-muted-foreground">
            <ArrowLeft className="mr-2 h-4 w-4" /> Back to Home
          </Link>
        </Button>
      </div>
    );
  },
);
